export const SCHOOL_STATUS = {
  UNIMPORTED: "unimported",
  IMPORTED_RECORDS: "imported-records",
  IMPORTED_NO_RECORDS: "imported-no-records",
  NO_SEATS: "no-seats",
  OPEN_SEATS: "open-seats",
  SI_INCOMPLETE: "si-incomplete",
  READY: "ready",
};

//enum for SIS school sync status

export const SCHOOL_SYNC_STATUS = {
  FAILED: "failed",
  PENDING: "pending",
  PARTIAL_SUCCESS: "partial_success",
  SUCCESS: "success",
  NO_RECORDS: "no_records",
};

//enum for SA roster status

export const ROSTER_STATUS = {
  NOT_STARTED: "not_started",
  SELECTED: "selected",
  SUBMITTED: "submitted",
  ENROLLED: "enrolled",
};
