import { SPECIAL_EDUCATION_PROGRAM } from "constants/metadata/special_education_program";
import { SPECIAL_EDUCATION_PROGRAM_ACCOMMODATIONS } from "constants/metadata/special_education_program_accommodations";
import { GRADE_LEVEL } from "constants/metadata/grade_level";
import { LANGUAGE_SPOKEN_AT_HOME } from "constants/metadata/language_spoken_at_home";
import { LUNCH_PROGRAM } from "constants/metadata/lunch_program";
import { RACE } from "constants/metadata/race";
import { BOOLEANS } from "constants/metadata/boolean";
import { SORT_DIRECTION, SORT_TYPE } from "constants/enums/sort";
import { DATA_TYPE } from "./data_type";
import { VALIDATION_TYPE } from "constants/enums/validation";

export const studentColumns = [
  {
    colName: "Student Name",
    editable: false,
    dataType: DATA_TYPE.STRING,
    sortType: SORT_TYPE.ALPHANUMERIC,
    activeSortDirection: SORT_DIRECTION.ASCENDING,
    key: "name",
  },
  {
    colName: "Student Grade",
    editable: true,
    dataType: DATA_TYPE.SELECT,
    sortType: SORT_TYPE.GRADE,
    activeSortDirection: SORT_DIRECTION.NONE,
    options: GRADE_LEVEL,
    key: "grade-level",
  },
  {
    colName: "Student ID Number",
    editable: false,
    dataType: DATA_TYPE.STRING,
    sortType: SORT_TYPE.ALPHANUMERIC,
    activeSortDirection: SORT_DIRECTION.NONE,
    key: "roster-integration-external-id",
  },
  {
    colName: "Student Email",
    editable: true,
    dataType: DATA_TYPE.STRING,
    sortType: SORT_TYPE.ALPHANUMERIC,
    activeSortDirection: SORT_DIRECTION.NONE,
    validationType: VALIDATION_TYPE.EMAIL,
    key: "email",
  },
  {
    colName: "Student Tutoring Time Slot",
    editable: true,
    dataType: DATA_TYPE.SELECT,
    sortType: SORT_TYPE.DISABLED,
    activeSortDirection: SORT_DIRECTION.NONE,
    validationType: VALIDATION_TYPE.SCHOOL_SEAT,
    // Options are specific to the school so are added in the route
    key: "school-seat-id",
  },
  {
    colName: "Teacher Name",
    editable: false,
    dataType: DATA_TYPE.STRING,
    sortType: SORT_TYPE.ALPHANUMERIC,
    activeSortDirection: SORT_DIRECTION.NONE,
    key: "teacher-name",
  },
  {
    colName: "Teacher Email",
    editable: false,
    dataType: DATA_TYPE.STRING,
    sortType: SORT_TYPE.ALPHANUMERIC,
    activeSortDirection: SORT_DIRECTION.NONE,
    key: "teacher-email",
  },
  {
    colName: "Student DOB",
    editable: false,
    dataType: DATA_TYPE.DATE,
    sortType: SORT_TYPE.ALPHANUMERIC,
    activeSortDirection: SORT_DIRECTION.NONE,
    key: "date-of-birth",
  },
  {
    colName: "Hispanic or Latino Descent",
    editable: true,
    dataType: DATA_TYPE.SELECT,
    sortType: SORT_TYPE.ALPHANUMERIC,
    activeSortDirection: SORT_DIRECTION.NONE,
    options: BOOLEANS,
    key: "hispanic-or-latino-descent",
  },
  {
    colName: "Race",
    editable: true,
    dataType: DATA_TYPE.SELECT,
    sortType: SORT_TYPE.ALPHANUMERIC,
    activeSortDirection: SORT_DIRECTION.NONE,
    options: RACE,
    key: "race",
  },
  {
    colName: "Language Spoken at Home",
    editable: true,
    dataType: DATA_TYPE.SELECT,
    sortType: SORT_TYPE.ALPHANUMERIC,
    activeSortDirection: SORT_DIRECTION.NONE,
    options: LANGUAGE_SPOKEN_AT_HOME,
    key: "language-spoken-at-home",
  },
  {
    colName: "Multilingual Learner",
    editable: true,
    dataType: DATA_TYPE.SELECT,
    sortType: SORT_TYPE.ALPHANUMERIC,
    activeSortDirection: SORT_DIRECTION.NONE,
    options: BOOLEANS,
    key: "english-language-learner",
  },
  {
    colName: "Special Education Program",
    editable: true,
    dataType: DATA_TYPE.SELECT,
    sortType: SORT_TYPE.ALPHANUMERIC,
    activeSortDirection: SORT_DIRECTION.NONE,
    options: SPECIAL_EDUCATION_PROGRAM,
    validationType: VALIDATION_TYPE.SEP,
    noneLabel: "sep_none",
    key: "special-education-program",
  },
  {
    colName: "Special Education Program Accommodations",
    editable: true,
    dataType: DATA_TYPE.MULTISELECT,
    sortType: SORT_TYPE.DISABLED,
    activeSortDirection: SORT_DIRECTION.NONE,
    options: SPECIAL_EDUCATION_PROGRAM_ACCOMMODATIONS,
    dependency: "special-education-program",
    validationType: VALIDATION_TYPE.SEP_ACCOMMODATIONS,
    key: "special-education-program-accommodations",
  },
  {
    colName: "Free/Reduced Lunch Program",
    editable: true,
    dataType: DATA_TYPE.SELECT,
    sortType: SORT_TYPE.ALPHANUMERIC,
    activeSortDirection: SORT_DIRECTION.NONE,
    options: LUNCH_PROGRAM,
    key: "lunch-program",
  },
  {
    colName: "Student Start Date",
    editable: false,
    dataType: DATA_TYPE.DATE,
    sortType: SORT_TYPE.ALPHANUMERIC,
    activeSortDirection: SORT_DIRECTION.NONE,
    key: "start-date",
  },
  {
    colName: "Student End Date",
    editable: false,
    dataType: DATA_TYPE.DATE,
    sortType: SORT_TYPE.ALPHANUMERIC,
    activeSortDirection: SORT_DIRECTION.NONE,
    key: "end-date",
  },
];
