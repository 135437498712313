import { SCHOOL_STATUS } from "constants/enums/school_status";
import { useRouteLoaderData } from "react-router-dom";
import { Navigate } from "react-router-dom";

/**
 * Component which redirects the user to the appropriate page based on the user's school data.
 */
export default function LandingRedirect() {
  const { schools } = useRouteLoaderData("auth");

  const multipleSchools = schools.length > 1;
  const status = schools.length > 0 ? schools[0].status : null;

  let url;
  if (multipleSchools) {
    url = "/school-selection";
  } else if (status === SCHOOL_STATUS.IMPORTED_RECORDS) {
    url = "/student-setup";
  } else if (status === SCHOOL_STATUS.IMPORTED_NO_RECORDS) {
    url = "/no-student-records";
  } else if (status === SCHOOL_STATUS.NO_SEATS) {
    url = "/no-seats";
  } else if (status === SCHOOL_STATUS.SI_INCOMPLETE) {
    url = "/si-incomplete";
  } else if (status === SCHOOL_STATUS.OPEN_SEATS) {
    url = "/open-seats";
  } else if (status === SCHOOL_STATUS.READY) {
    url = "/si-complete";
  } else {
    url = "/sis-not-imported";
  }

  return (
    <Navigate
      to={url}
      replace={true}
    />
  );
}
