import StudentSelectionView from "views/StudentSelection";
import SchoolService from "services/School";
import AuthService from "services/Auth";
import { redirect } from "react-router-dom";
import { ENROLLMENT_STATUS } from "constants/enums/enrollment";

const pushStudent = (students, student, selected) => {
  students.push({
    uuid: student.split("_")[1],
    student_program_year_uuid: student.split("_")[2],
    enrollment_status: selected
      ? ENROLLMENT_STATUS.SELECTED
      : ENROLLMENT_STATUS.NOT_SELECTED,
  });
};

export default {
  element: <StudentSelectionView />,
  path: "student-selection",
  action: async ({ params, request }) => {
    const SPHINX_GATE_API_URL = import.meta.env.VITE_SPHINX_GATE_API_URL;
    const { schoolId } = params;

    await AuthService.auth();
    if (AuthService.isAuthenticated) {
      SchoolService.api.token = AuthService.api.token;
      const formData = await request.formData();

      // ****************************
      // Validations
      // ****************************
      const numOpenSeats = formData.get("num-open-seats");
      if (numOpenSeats < 0) {
        return [
          {
            message:
              "Oops! You've selected more students than available seats.",
          },
        ];
      }

      // ****************************
      // Submit to spark
      // ****************************
      const selectedStudents =
        formData.get("selected-students")?.split(",") || [];
      const deselectedStudents =
        formData.get("deselected-students")?.split(",") || [];
      const students = [];

      selectedStudents.forEach(fieldName => {
        fieldName !== "" && pushStudent(students, fieldName, true);
      });

      deselectedStudents.forEach(fieldName => {
        fieldName !== "" && pushStudent(students, fieldName, false);
      });

      if (students.length > 0) {
        const updateData = { students };

        await SchoolService.updateStudents(schoolId, updateData);
      }

      return redirect(`/schools/${schoolId}/student-info-completion`);
    }
    return redirect(`${SPHINX_GATE_API_URL}/login`);
  },
};
