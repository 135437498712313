export const fullGradeDict = {
  pre_kindergarten: 0,
  kindergarten: 1,
  first_grade: 2,
  second_grade: 3,
  third_grade: 4,
  fourth_grade: 5,
  fifth_grade: 6,
  sixth_grade: 7,
  seventh_grade: 8,
  eighth_grade: 9,
  ninth_grade: 10,
  tenth_grade: 11,
  eleventh_grade: 12,
  twelfth_grade: 13,
};
