import Button from "components/Button";
import PropTypes from "prop-types";
import twcx from "utils/twcx";

export function Legend({ title, closeLegend, children, ...args }) {
  return (
    <div
      className="p-7 max-w-[372px] border-[1px] rounded-[6px] border-ignite-grey-border"
      {...args}>
      <h1 className="text-xl font-bold mb-4">{title}</h1>
      {children}
      <div className="w-full flex justify-center">
        <Button
          className="py-2 px-12"
          onClick={closeLegend}>
          Got It
        </Button>
      </div>
    </div>
  );
}

Legend.propTypes = {
  title: PropTypes.string.isRequired,
  closeLegend: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export function LegendItem({
  legendStyles,
  title,
  subtitle,
  description,
  // This changes the bottom margin to ensure the space between this item and the next item is consistent when the next item is centered in a larger div
  isNextItemMultiLine = false,
}) {
  return (
    <div
      className={`flex items-center ${isNextItemMultiLine ? "mb-2" : "mb-4"}`}>
      <div
        className={twcx(
          "w-[32px] h-[34px] border-[1px] border-black border-opacity-50 rounded-[5px] shrink-0 mr-4",
          legendStyles
        )}
      />
      <div>
        <h2 className="mt-1">
          <span className="font-bold">{title}</span>
          <span className="font-light">{subtitle}</span>
        </h2>
        <p className="text-sm">{description}</p>
      </div>
    </div>
  );
}

LegendItem.propTypes = {
  legendStyles: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  isNextItemMultiLine: PropTypes.bool,
};
