import { useEffect, useState } from "react";
import { useNavigate, useRouteLoaderData } from "react-router-dom";

export default function NoSeatsView() {
  const { schools } = useRouteLoaderData("auth");
  const navigate = useNavigate();

  const [school, setSchool] = useState(null);

  useEffect(() => {
    if (schools.length > 1) {
      navigate("/school-selection");
    } else {
      setSchool(schools[0]);
    }
  }, [schools]);

  return (
    <>
      <div className="content-center mx-[5%] xl:mx-40 navbar-height-offset text-center">
        <h1
          className="text-ignite-purple-dark text-[24px] mb-4"
          data-testid="no-seats-message">
          {school?.name} seat count not yet configured.
        </h1>
        <h2 className="text-ignite-purple-dark text-[40px] font-bold leading-[48px] mb-7">
          Please reach out to your <br /> Customer Success Manager.
        </h2>
      </div>
    </>
  );
}
