import PropTypes from "prop-types";
import Logo from "assets/logos/white-logo.svg";
import { Form, useActionData, useLoaderData } from "react-router-dom";
import { Input, Anchor, Button } from "components";

export default function SignUpView() {
  const { email, schoolDistrictName, schoolName, token } = useLoaderData();
  const errors = useActionData();

  const passwordInvalid = Boolean(errors?.password);
  const confirmInvalid = Boolean(errors?.confirm);

  const SPHINX_GATE_API_URL = import.meta.env.VITE_SPHINX_GATE_API_URL;

  return (
    <main className="flex h-full w-screen">
      <div className="relative min-w-[28rem] w-1/4 bg-top bg-cover bg-[url('../assets/images/school-child.png')]">
        <div className="absolute z-0 top-0 left-0 bg-ignite-purple-dark h-full w-full bg-opacity-80" />
        <div className="relative z-1 pt-16 mx-11">
          <Logo aria-label="Ignite Reading Logo" />
          <h1 className="text-white font-bold text-2xl mt-5">{`Welcome to the ${schoolName} School Portal!`}</h1>
          <p className="text-white font-normal text-xl mt-5">
            This portal will provide the tools you need to manage the Ignite
            Reading program effectively and track your students&apos; progress.
          </p>
        </div>
      </div>
      <div className="grow flex justify-center px-6">
        <div className="py-20 w-[39rem]">
          <h1
            data-testid="welcome-message"
            className="text-ignite-purple-dark font-bold text-3xl">
            Sign Up
          </h1>
          <p className="text-base	font-bold py-4 text-ignite-grey-dark">
            We&apos;re thrilled to have you on board.
            <br />
            <span className="font-normal text-ignite-grey-dark">
              Let&apos;s get started on setting up your account!
            </span>
          </p>

          <p className="text-ignite-purple-dark font-semibold text-sm">Email</p>
          <p className="text-xl text-ignite-grey-dark pb-8">{email}</p>

          <p className="text-ignite-purple-dark font-semibold text-sm">
            School District
          </p>
          <p className="text-xl text-ignite-grey-dark pb-8">
            {schoolDistrictName}
          </p>

          <Form
            data-testid="signup-form"
            method="post"
            className="w-full grid grid-cols-2 gap-x-5">
            <Input
              placeholder="First"
              label="First Name"
              name="firstName"
              autoComplete="given-name"
              required
              testID="first-name"
            />
            <Input
              placeholder="Last"
              label="Last Name"
              name="lastName"
              autoComplete="family-name"
              required
              testID="last-name"
            />
            <Input
              className="col-span-2"
              placeholder="Password"
              error={passwordInvalid}
              errorMessage="Please enter a password that meets the requirements."
              additionalMessage={
                <span>
                  (<span className="font-bold">Password Requirements:</span> Min
                  8 characters, 1 upper case, 1 lower case, 1 number, 1 symbol.)
                </span>
              }
              label="Password"
              name="password"
              autoComplete="new-password"
              required
              testID="password"
              type="password"
            />
            <Input
              className="col-span-2"
              placeholder="Confirm Password"
              error={confirmInvalid}
              errorMessage="Passwords do not match."
              label="Confirm Password"
              name="confirm"
              required
              testID="confirm"
              type="password"
            />
            <input
              type="hidden"
              name="token"
              value={token}
            />
            <div className="col-span-2 mt-20">
              <p className="text-sm text-center">
                {"By signing up you are agreeing to Ignite Reading's "}
                <Anchor
                  target="blank"
                  href="https://ignite-reading.com/terms/">
                  Terms of Service
                </Anchor>
                {" and "}
                <Anchor
                  target="blank"
                  href="https://ignite-reading.com/privacy-policy/">
                  Privacy Policy.
                </Anchor>
              </p>
              <Button
                className="w-full my-4 h-14 font-semibold"
                testID="create-account"
                type="submit">
                Create Account
              </Button>
              <div className="flex mt-5 mb-20">
                <Anchor
                  href={`${SPHINX_GATE_API_URL}/login`}
                  className="m-auto text-ignite-purple-dark underline hover:no-underline focus:bg-ignite-purple-dark focus:text-white">
                  I already have an account.
                </Anchor>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </main>
  );
}

SignUpView.propTypes = {
  email: PropTypes.string,
  schoolDistrictName: PropTypes.string,
  schoolName: PropTypes.string,
  token: PropTypes.string,
};
