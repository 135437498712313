export default class School {
  constructor(ApiService) {
    this.api = ApiService;
  }

  async school(id) {
    const { status, data } = await this.api.fetch({
      url: `schools/${id}`,
    });
    if (status == 200) {
      return { status, school: data };
    } else if (status == 404) {
      return { status, message: data.message };
    } else {
      throw {
        status,
        message: data.message,
      };
    }
  }

  async schools() {
    const { status, data } = await this.api.fetch({
      url: "schools",
    });
    if (status == 200) {
      return { status, schools: data };
    }
    throw { status, message: data.message };
  }

  async students(id) {
    const { status, data } = await this.api.fetch({
      url: `schools/${id}/students`,
    });
    if (status == 200) {
      return { status, students: data };
    }
    throw { status, message: data.message };
  }

  async updateStudents(id, students) {
    const { status, data } = await this.api.fetch({
      url: `schools/${id}/students`,
      method: "post",
      body: students,
    });
    if (status == 200) {
      return { status, students: data };
    } else if (status == 422) {
      throw { status, message: data.message };
    } else {
      throw {
        status,
        message:
          "Unable to save student data. Please contact your Ignite Reading Customer Success Representative.",
      };
    }
  }

  async tokenInfo(token) {
    const { status, data } = await this.api.fetch({
      url: `user_tokens/${token}`,
    });
    return { status, data };
  }

  async signout() {
    this.api.token = null;
    this.isAuthenticated = false;
    return true;
  }
}
