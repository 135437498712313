import {
  useActionData,
  useNavigation,
  useRouteLoaderData,
} from "react-router-dom";
import { DataGrid, OpenSeats } from "components";
import { useEffect, useMemo, useState } from "react";
import { ENROLLMENT_STATUS } from "constants/enums/enrollment";
import { getTotalSeats } from "utils/schools";

export default function StudentSelectionView() {
  const errors = useActionData();
  const { school, students, studentColumns } = useRouteLoaderData("students");

  const submittedOrEnrolledStudents = useMemo(
    () =>
      students.filter(student =>
        [ENROLLMENT_STATUS.SUBMITTED, ENROLLMENT_STATUS.ENROLLED].includes(
          student.enrollment_status
        )
      ).length,
    [students]
  );

  const [currStudents, updateCurrStudents] = useState(
    students.filter(student =>
      [ENROLLMENT_STATUS.NOT_SELECTED, ENROLLMENT_STATUS.SELECTED].includes(
        student.enrollment_status
      )
    )
  );

  const [selectedStudents, setSelectedStudents] = useState([]);
  const [deselectedStudents, setDeselectedStudents] = useState([]);
  const [isDataSaving, setIsDataSaving] = useState(false);
  const navigation = useNavigation();

  const getSelectedSeats = () =>
    currStudents.filter(row => row.selected).length;

  const [numOpenSeats, setNumOpenSeats] = useState(
    getTotalSeats(school) - submittedOrEnrolledStudents - getSelectedSeats()
  );

  useEffect(() => {
    setNumOpenSeats(
      getTotalSeats(school) - submittedOrEnrolledStudents - getSelectedSeats()
    );
  }, [currStudents]);

  const onCheck = ({ name, checked }) => {
    const studentUUID = name.split("_")[1];
    const selectedStudentIndex = currStudents.findIndex(
      student => student.uuid === studentUUID
    );
    currStudents[selectedStudentIndex].selected = checked;
    updateCurrStudents([...currStudents]);

    if (checked) {
      // Add to selected list and remove from deselected list
      setSelectedStudents(prev => [...prev, name]);
      setDeselectedStudents(prev => prev.filter(id => id !== name));
    } else {
      // Add to deselected list and remove from selected list
      setDeselectedStudents(prev => [...prev, name]);
      setSelectedStudents(prev => prev.filter(id => id !== name));
    }
  };

  // useEffect that triggers whenever data processing state changes
  useEffect(() => {
    setIsDataSaving(navigation.state == "submitting");
  }, [navigation.state]);

  return (
    <div className="flex flex-row justify-between mx-24">
      <div className="mt-16">
        <h1 className="text-ignite-purple-dark text-2xl font-semibold">
          Select Students for the Program
        </h1>
        <p className="mt-2 mb-10 text-ignite-grey-dark">
          Select students that you would like to enroll in the current
          programming session.
        </p>
        <div className="w-fit mb-12">
          <DataGrid
            columns={studentColumns}
            rows={currStudents}
            keys={["name", "teacher-name", "grade-level"]}
            multiSelect
            disableSelect={numOpenSeats <= 0}
            allowEdits={false}
            onSelect={e =>
              onCheck({
                name: e.target.name,
                checked: e.target.checked,
              })
            }
            height="600px"
            formId="student-selection-form"
          />
          <input
            aria-hidden={true}
            form="student-selection-form"
            className="invisible absolute bottom-0"
            name="num-open-seats"
            value={numOpenSeats}
            readOnly
          />
          <input
            aria-hidden={true}
            form="student-selection-form"
            className="invisible absolute bottom-0 w-full"
            name="selected-students"
            value={selectedStudents}
            readOnly
          />
          <input
            aria-hidden={true}
            form="student-selection-form"
            className="invisible absolute bottom-0 w-full"
            name="deselected-students"
            value={deselectedStudents}
            readOnly
          />
        </div>
      </div>
      <div className="flex flex-col items-end mr-10">
        <OpenSeats
          numOpenSeats={numOpenSeats}
          numSelectedSeats={getSelectedSeats() + submittedOrEnrolledStudents}
          numTotalSeats={getTotalSeats(school)}
          className="mt-14 ml-6"
        />
        <DataGrid.ErrorSummary errors={errors || []}>
          <DataGrid.ErrorSummary.Submit
            design={isDataSaving ? "disabled" : "primary"}
            formId="student-selection-form"
            className="py-3 px-8 mt-16"
            disabled={isDataSaving}>
            Save & Next
          </DataGrid.ErrorSummary.Submit>
          <DataGrid.ErrorSummary.Content />
        </DataGrid.ErrorSummary>
      </div>
    </div>
  );
}
