import classnames from "classnames";
import uniq from "lodash/uniq";
import { useContext, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { Button, Popover, PopoverContent, PopoverTrigger } from "components";
import Options from "./Options";
import PropTypes from "prop-types";
import DownArrowIcon from "assets/icons/arrow_down.svg";
import SelectedOptions from "./SelectedOptions";
import { WithWrapperRef } from "../WithWrapper";
import { DataGridContext } from "components/DataGrid/DataGridProvider";

export default function DataGridMultiSelect({
  error = false,
  disabled = false,
  className,
  label = "Select",
  columnHeader,
  name,
  options,
  values,
  id,
  shouldStartPink,
  onBlur,
  ...args
}) {
  const { onEditSaveProgress } = useContext(DataGridContext);

  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState(values || []);
  const [contentWidth, setContentWidth] = useState(null);

  const triggerRef = useRef();
  const contentRef = useRef();

  useEffect(() => {
    if (open && triggerRef.current) {
      const width = triggerRef.current.getBoundingClientRect().width;
      setContentWidth(width);
    }
  }, [open]);

  const onChangeInput = event => {
    setSelectedValues(prevValues => {
      return event.target.checked
        ? uniq([...prevValues, event.target.value])
        : prevValues.filter(value => value !== event.target.value);
    });
  };

  useEffect(() => {
    onEditSaveProgress({
      target: { name, values: selectedValues, multiselect: true },
    });
  }, [selectedValues]);

  // Needed for sorting
  useEffect(() => {
    setSelectedValues(values || []);
  }, [values]);

  const onApply = () => {
    triggerRef.current?.focus({ preventScroll: true });
    setOpen(false);
  };
  const onReset = () => setSelectedValues([]);

  const handleOpen = bool => {
    setOpen(bool);
    if (!bool) triggerRef.current?.focus({ preventScroll: true });
  };

  return (
    <Popover
      onOpenChange={handleOpen}
      open={open}
      placement="bottom"
      offset={{ mainAxis: 0 }}
      useFlip={false}
      useShift={false}
      toggle>
      <PopoverTrigger>
        <WithWrapperRef
          shouldStartPink={shouldStartPink}
          value={selectedValues.length}>
          <button
            className={twMerge(
              classnames(
                "group flex items-center whitespace-nowrap h-full w-full justify-center",
                "outline-2 outline-offset-[-1px] focus:rounded focus:outline focus:outline-ignite-pink",
                {
                  "shadow-inner rounded outline outline-ignite-pink": open,
                }
              )
            )}
            data-testid={`${id}-multiselect`}
            aria-label={`Select ${columnHeader}`}
            // NOTE: we disable this error because floating-ui adds aria attributes FOR us
            // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
            role="combobox"
            aria-haspopup="listbox"
            id={id}
            ref={triggerRef}
            disabled={disabled}
            type="button"
            {...args}>
            {open || selectedValues.length === 0 ? (
              <>
                <label
                  htmlFor={id}
                  className={classnames({
                    "cursor-pointer": !disabled,
                    "text-error-red": error,
                  })}>
                  {label}
                </label>
                <input
                  aria-hidden={true}
                  className="invisible absolute bottom-0"
                  name={name}
                  value={"[]"}
                  readOnly
                />
              </>
            ) : (
              <SelectedOptions
                selectedValues={selectedValues}
                options={options}
                name={name}
                error={error}
              />
            )}
            {/* hidden input to handle invalid state */}
            <input
              aria-invalid={error}
              aria-hidden={true}
              tabIndex={-1}
              className="invisible absolute bottom-0"
            />
            <DownArrowIcon
              className={classnames(
                "absolute pointer-events-none top-[calc(50%-.75em)] right-4 rounded-full box-content",
                {
                  "cursor-pointer group-focus:bg-ignite-pink group-hover:bg-ignite-pink group-focus:fill-white group-hover:fill-white":
                    !disabled,
                  "-rotate-180": open,
                }
              )}
            />
          </button>
        </WithWrapperRef>
      </PopoverTrigger>
      <PopoverContent
        hideArrow
        hideCloseButton
        className="rounded-t-none">
        <div
          ref={contentRef}
          data-testid={`${id}-multiselect-content`}
          className={twMerge(
            "flex flex-col max-h-[300px] rounded-b-lg overflow-hidden",
            className
          )}
          style={{ width: contentWidth ? `${contentWidth}px` : "auto" }}>
          <Options
            options={options}
            onChange={onChangeInput}
            selectedValues={selectedValues}
            name={name}
            onBlur={onBlur}
          />
          <div className="bg-zinc-50 border-t flex items-center px-3 py-2 rounded-b-lg">
            <Button
              className="py-2 w-[80%]"
              onClick={onApply}
              testID={`${id}-multiselect-apply-btn`}>
              Apply
            </Button>
            <Button
              className="px-2 m-auto"
              design="link"
              onClick={onReset}
              testID={`${id}-multiselect-reset-btn`}>
              Reset
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}

DataGridMultiSelect.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  columnHeader: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
      ]).isRequired,
    })
  ).isRequired,
  values: PropTypes.array,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  shouldStartPink: PropTypes.bool.isRequired,
  onBlur: PropTypes.func,
};
