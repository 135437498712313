import StudentInfoCompletionView from "views/StudentInfoCompletion";
import {
  parseStrings,
  studentDataDeDecoration,
  studentDataValidation,
} from "utils/studentDataProcessing";
import SchoolService from "services/School";
import AuthService from "services/Auth";
import { redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { ENROLLMENT_STATUS } from "constants/enums/enrollment";
import { VALIDATION_TYPE } from "constants/enums/validation";

export default {
  element: <StudentInfoCompletionView />,
  path: "student-info-completion",
  action: async ({ params, request }) => {
    const SPHINX_GATE_API_URL = import.meta.env.VITE_SPHINX_GATE_API_URL;
    const { schoolId } = params;

    await AuthService.auth();
    if (AuthService.isAuthenticated) {
      SchoolService.api.token = AuthService.api.token;
      const formData = await request.formData();
      const formObject = Object.fromEntries(formData);

      // ****************************
      // Validations
      // ****************************
      let maxValuesForAggValidations = {};

      const toastId = toast.loading("Validating data...");
      let loadingMessage;
      let successMessage;
      let dataValidation = {};

      try {
        const { school, status } = await SchoolService.school(schoolId);

        if (status == 404 || !school) {
          return null;
        }

        maxValuesForAggValidations[VALIDATION_TYPE.SCHOOL_SEAT] =
          school?.school_seats;

        dataValidation = studentDataValidation(
          formObject,
          maxValuesForAggValidations
        );

        const students = await studentDataDeDecoration(
          formObject,
          dataValidation.errorCells
        );
        const updateData = { students };

        const submitStudents = parseStrings(
          "submit-students",
          formData.get("submit-students")
        );

        if (!submitStudents || dataValidation.error) {
          loadingMessage = "Saving student data...";
          successMessage = "All students have been saved.";
        } else {
          students.forEach(student => {
            student.enrollment_status = ENROLLMENT_STATUS.SUBMITTED;
          });
          loadingMessage = "Submitting students for review...";
          successMessage = "All students have been submitted.";
        }

        toast.update(toastId, {
          render: loadingMessage,
          type: "info",
          isLoading: true,
        });

        // ****************************
        // Submit to spark
        // ****************************
        await SchoolService.updateStudents(schoolId, updateData);

        if (!dataValidation.error) {
          toast.update(toastId, {
            render: successMessage,
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        } else {
          toast.dismiss(toastId);
        }

        if (submitStudents && !dataValidation.error) {
          return redirect(`/schools/${schoolId}/student-info-review`);
        }
      } catch (updateStudentsError) {
        const { message } = updateStudentsError;
        toast.update(toastId, {
          render: message,
          type: "error",
          isLoading: false,
          autoClose: false,
          closeOnClick: true,
        });
      }

      // ****************************
      // Return error messages
      // ****************************
      if (dataValidation.error) {
        return {
          errorMessages: dataValidation.errorMessages,
          errorCells: dataValidation.errorCells,
        };
      }
      return null;
    }
    return redirect(`${SPHINX_GATE_API_URL}/login`);
  },
};
